<template>
  <div class="main-div">
    <div class="top animate">
      <div class="contact-image">
        <img src="../../../../assets/images/data-processing-icon.svg" alt="" />
        <p>Web Hook Enrichment Logs</p>
      </div>
      <div class="refresh-btn">
        <button class="secondary-btn-outline" @click="handleRouteChange">
          Back
        </button>
      </div>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="dg-new-action-filter action-btn filter-container">
        <div class="search-container">
          <div class="searchicon">
            <!-- Your SVG code here -->

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11" cy="11" r="8" stroke="#828282" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"></circle>
              <path d="M16.5 16.9579L21.5 21.958" stroke="#828282" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"></path>
            </svg>
          </div>
          <input type="text" class="searchbar" placeholder="Search" v-model="formData.search" />
        </div>

        <date-range-picker v-model="selectedDateRange" @start-selection="handleStartSelection"
          @finish-selection="handleFinishSelection" />

        <div class="single-card-box" style="text-align: center;">
          <b-form-select v-model="formData.status" :options="Statusoptions"></b-form-select>
        </div>

        <div class="refresh-btn">
          <button :disabled="isButtonDisabled" type="submit" v-bind:class="isButtonDisabled
          ? 'secondary-btn-disabled'
          : ' secondary-btn-outline '
          ">
            Search
          </button>
        </div>

        <div class="refresh-btn">
          <button @click="clearFilters" :disabled="isButtonDisabled" v-bind:class="isButtonDisabled
          ? 'secondary-btn-disabled'
          : ' secondary-btn-outline '
          ">
            Clear Filters
          </button>
        </div>
      </div>
    </form>

    <b-card no-body>
      <div class="start-table text-center mb-5">
        <table class="dp-webhook-table first-tables table-new">
          <thead>
            <tr class="Head_table">
              <th class="table_head">HubSpot Id</th>
              <th class="table_head">HubSpot Email</th>
              <th class="table_head">Job Change Detected</th>

              <th class="table_head">First Name</th>
              <th class="table_head">Last Name</th>
              <!-- <th class="table_head">Status</th> -->
              <th class="table_head">Person Enrich Status</th>
              <th class="table_head">Company Enrich Status</th>
              <th class="table_head">Curl Link</th>
              <th class="table_head">Message</th>

              <th class="table_head">Credit Used</th>
              <th class="table_head">Created Date</th>
            </tr>
          </thead>
          <!-- <tbody v-if="HookData.length === 0">
            <tr>
              <td colspan="10">
                <p class="no-record-found">No Record found</p>
              </td>
            </tr>
          </tbody> -->

          <tbody>
            <tr v-if="HookData.length === 0">
              <td colspan="10">
                <p class="no-record-found">No Record found</p>
              </td>
            </tr>
            <tr v-else v-for="(data, index) in HookData" :key="index">
              <td>{{ data.contact_hub_id }}</td>

              <td class="overflow-vissible">
                <div class="dg-imail-patch">
                  {{ data.contact_hub_email }}
                </div>
                <div class="dg-tooltip">
                  {{ data.contact_hub_email }}
                </div>
              </td>

              <td style="text-align: center">
                <span v-if="data.is_job_change_detected === 1" class="true-icon">
                  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <!-- fill="#20B89E" -->
                    <path
                      d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                      fill="#fff"></path>
                  </svg>
                </span>
                <!-- <span v-else class="cross-button">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    stroke="#FF0000"
                    <path
                      d="M1 1L21 21M21 1L1 21"
                      stroke="#FFf"
                      stroke-width="2"
                    ></path>
                  </svg>
                </span> -->
                <img src="../../../../assets/images/icons/minus.svg" alt="" style="height: 20px; width: 20px;" />
              </td>

              <td>
                {{ data.contact_hub_f_name }}
              </td>
              <td>
                {{ data.contact_hub_l_name }}
              </td>
              <!-- <td
                class="status-td"
                v-bind:class="
                  data.status == 'Success'
                    ? 'completed'
                    : data.status == 'Error'
                    ? 'duplicate'
                    : ''
                "
              >
                <span>{{ data.status }}</span>
              </td> -->
              <td style="text-align: center;">
                <!-- true -->
                <img v-if="data.person_enrich_status == 1" src="../../../../assets/images/true-item-icon.svg" alt="" />
                <!-- false -->
                <img v-else src="../../../../assets/images/icons/minus.svg" alt="" style="height: 20px; width: 20px;" />
              </td>
              <td style="text-align: center;">
                <!-- true -->
                <img v-if="data.company_enrich_status == 1" src="../../../../assets/images/true-item-icon.svg"
                  alt="" />
                <!-- false -->
                <img v-else src="../../../../assets/images/icons/minus.svg" alt="" style="height: 20px; width: 20px;" />
              </td>
              <td style="text-align: center;">
                <span v-if="data.curl_req ==
          'The cURL request is not initiated because the contact does not contain an email address.' ||
          data.curl_req ==
          'No curl request is initiated when the contact information includes a Gmail address.' ||
          data.curl_req ==
          'The curl request is not being made because the contact does not contain the required properties' ||
          data.curl_req === ''
          ">{{ data.curl_req || "No curl request generated yet" }}</span>
                <button v-else class="databtn" @click="copyCurl(data.curl_req)">
                  <!-- Copy -->
                  <img src="../../../../assets/images/copy-item-icon.svg" alt="" />
                </button>
              </td>
              <td>{{ data.enrich_message }}</td>

              <td>{{ data.creditUsed }}</td>
              <td>{{ data.created_at | timeAgo }}</td>
            </tr>
            <tr ref="scrollTrigger"></tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  GET_WEBHOOK_LOGS,
  GET_WEBHOOK_EMAIL_LIST,
} from "../../../store/constant/actions.type";
import io from "socket.io-client";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// D:\Projects\razot\datagma\datagama\src\app\main\common\services\loading.service.js
import { loadingService } from "../../../../../src/app/main/common/services/loading.service";
import Vue from "vue";
import moment from "moment";
// Define a global filter for "time ago"
Vue.filter("timeAgo", function (value) {
  if (value) {
    return moment(value).fromNow();
  }
});
export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      selectedDateRange: { startDate: new Date(), endDate: new Date() },
      isFilterStatus: false,
      formData: {
        search: "",
        email: "",
        status: "",
        page: 1,
        pageSize: 30,
        startDate: this.formatToYYYYMMDD(new Date()),
        endDate: this.formatToYYYYMMDD(new Date()),
      },
      filterTerm: false,
      observer: null,
      HookData: [],
      loading: false,
      loginUseremail: "",
      options: [],
      Statusoptions: [
        { value: "", text: "Choose Status" },
        { value: "Success", text: "Success" },
        { value: "Error", text: "Error" },
      ],
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.formData.search === "" &&
        this.formData.email === "" &&
        this.formData.status === "" &&
        this.formData.startDate === "" &&
        this.formData.endDate === ""
      );
    },
  },
  created() {
    this.loadData();
  },
  // 3. beforeMount hook
  beforeMount() {
    const userData = this.$store.getters.getstoreUser;
    this.loginUseremail = userData?.email;
    console.log("check email", this.loginUseremail);
    this.getWebhookData(); // Corrected to use 'this' to call the method
    this.getEmailsData();
    // DOM is not yet mounted
  },
  // 4. mounted hook
  mounted() {
    // Set up Intersection Observer
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.$refs.scrollTrigger);

    const url = process.env.VUE_APP_NODE_URL;
    const socket = io(url, {
      path: "/dgsocket",
    });
    socket.on("wh_data_log", (wh_data_log) => {
      console.log("this.isFilterStatus", this.isFilterStatus);
      console.log("this.loginUseremail", this.loginUseremail);
      console.log("wh_data_log", wh_data_log);
      if (this.loginUseremail == wh_data_log?.email) {
        this.HookData.unshift(wh_data_log);
      }
    });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  // 8. destroyed hook
  destroyed() { },
  methods: {
    handleRouteChange() {
      this.$router.push({ name: "enrich-contact" });
    },
    copyCurl(curl) {
      const textarea = document.createElement("textarea");
      textarea.value = curl;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      // Optionally, provide user feedback (e.g., display a message)
      this.flashMessage.success({
        title: "success",
        message: "Curl Copied to ClipBoard",
        time: 3000,
      });
    },
    handleStartSelection(newDateRange) {
      this.formData.startDate = this.formatToYYYYMMDD(newDateRange);
      console.log("aterval", newDateRange);
    },

    handleFinishSelection(newDateRange) {
      this.formData.endDate = this.formatToYYYYMMDD(newDateRange);
      console.log("aterval", this.formData);
    },

    formatToYYYYMMDD(dateString) {
      const originalTime = new Date(dateString);

      // Options for formatting the date
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      // Formatting the date using the options
      const formattedDate = originalTime.toLocaleDateString("en-US", options);

      return formattedDate;
    },
    async clearFilters() {
      this.selectedDateRange = { startDate: new Date(), endDate: new Date() };
      this.formData = {
        search: "",
        email: "",
        status: "",
        page: 1,
        pageSize: 30,
        startDate: this.formatToYYYYMMDD(new Date()),
        endDate: this.formatToYYYYMMDD(new Date()),
      };
      this.filterTerm = false;
      const Items = await this.getWebhookData();
      this.HookData = Items;
      this.isFilterStatus = false;
    },
    handleIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.loadData();
      }
    },
    async handleSubmit() {
      let SubmitData = this.formData;
      SubmitData.page = 1;
      const newdata = await this.OnSubmitGetWebhookData(SubmitData);

      this.HookData = newdata;
      this.filterTerm = true;
      // Add your form submission logic here
    },
    async loadData() {
      if (this.loading) return; // Prevent multiple simultaneous requests
      this.loading = true;
      this.filterTerm = false;
      // Simulate an API request (replace with your actual API request)
      const newItems = await this.getWebhookData();
      console.log("first", newItems);
      console.log("this.HookData", this.HookData);
      const uniqueNewItems = newItems.filter((newItem) => {
        return !this.HookData.some(
          (existingItem) => existingItem.id === newItem.id
        );
      });
      // Append new items to the existing array
      this.HookData = [...this.HookData, ...uniqueNewItems];

      this.formData.page++;
      this.loading = false;
    },
    async getWebhookData() {
      loadingService.setloader(true);
      let payload = this.formData;
      let res = await this.$store.dispatch(GET_WEBHOOK_LOGS, payload);
      loadingService.setloader(false);
      return res.data;
    },
    async OnSubmitGetWebhookData(payload) {
      loadingService.setloader(true);
      let res = await this.$store.dispatch(GET_WEBHOOK_LOGS, payload);
      if (payload.search || payload.email || payload.status) {
        this.isFilterStatus = true;
      }
      loadingService.setloader(false);
      return res.data;
    },

    getEmailsData() {
      this.$store
        .dispatch(GET_WEBHOOK_EMAIL_LIST)
        .then((response) => {
          if (response.status == "success" && response.data.length > 0) {
            if (response.data && response.data.length > 0) {
              this.options = [
                { value: "", text: "Choose Email" },
                ...response.data.map((email) => ({
                  value: email,
                  text: email,
                })),
              ];
            }
          }
        })
        .catch((err) => {
          if (err != undefined) {
            this.flashMessage.error({
              title: "Error",
              message: err.data.description,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
body {
  font-family: Semi Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 16px;
}

.top {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
}

.top p {
  margin: 0px 10px;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.table_head {
  background: #3f8aff;
  color: #ffff !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  width: 8% !important;
}

td {
  align-content: center;
}

/* tr td {
        width: 16%;
      } */
tr.R1 {
  color: #000000;
  width: 14%;
}

.start-table {
  margin-top: 24px;
  overflow-y: auto;
}

.first-tables {
  width: 100%;
  line-height: 45px;
  color: FFFFFF;
}

.start-table.bottom-area.table-custom {
  padding-top: 0;
}

.start-table.bottom-area.table-custom th {
  font-size: 14px;
  line-height: 1.2;
  vertical-align: middle;
}

/* .completed {
          color: #5BB645;
          border-color: #5BB645;
    
      } */
/* .file_not_found {
          color: #DA4E39;
          border-color: #DA4E39;
      } */
.modaldata ::v-deep .vm--modal {
  padding: 30px !important;
}

.action_button {
  margin-top: 60px;
  margin: auto;
  border: none !important;
}

.action_perform {
  /* width: 40%; */

  margin-left: 20px;
}

.action_perform:first-child {
  margin-left: 0;
}

.in_progress {
  color: #225fee;
}

.duplicate {
  color: #da4e39;
}

.paused {
  color: #225fee;
}

.action_button {
  width: 100%;
  padding: 6px 15px;
}

.enrichmodal {
  position: absolute;
  width: 100%;
  top: 56%;
  transform: translateY(-50%);
}

.enrich-head i {
  color: #003172;
  color: #fff;
}

.enrich-head {
  text-align: right;
  background: #3f6fcc;
  padding-right: 9px;
}

.vm--modal {
  width: 640px !important;
  height: 170px !important;
}

.table-new tr td {
  line-height: 25px;
  padding: 15px 13px;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  width: 13%;
}

.first-tables th {
  text-align: left;
  padding: 2px 13px;
}

.table-new tr td.status-td span {
  border-radius: 50px;
  display: inline-block;
  padding: 2px 9px 0px;
  font-size: 12px;
  font-weight: 700;
  background: #c7e2bc;
  color: green;
}

td span svg {
  height: 16px;
  widows: 16px;
}

p.completed {
  color: #5bb645;
  border: 1px solid #5bb645;
  padding: 3px;
  border-radius: 30px;
}

td.completed span {
  color: #5bb645;
  border: 1px solid #5bb645;
}

td.file_not_found span {
  color: #da4e39;
  border: 1px solid #da4e39;
}

td.in_progress span {
  color: #64b7d5;
  border: 1px solid #64b7d5;
}

td.duplicate span {
  border: 1px solid #da4e39;
  color: #da4e39;
}

.table-new tr td span.processed {
  color: #00a300;
}

.colorGreen {
  color: #00a300;
}

.table-new tr td span.errorProcess {
  color: #ff0000;
}

.start-table p {
  font-weight: 700;
  font-size: 26px;
  color: #000;
  line-height: 32px;
  margin-bottom: 5px;
}

.colorRed {
  color: #ff0000;
}

.action_button {
  border: 1px solid #3f8aff;
  border-radius: 18px;
  background: transparent;
  color: #3f8aff;
  font-weight: 600;
  text-transform: capitalize;
  /* padding: 5px 0px; */
  transition: 0.8s all;
}

.InRed:hover {
  background: #ff0000;
}

.InRed:hover span {
  color: #fff;
}

.InRed {
  border: 1px solid #ff0000;

  color: #ff0000;
  transition: 0.8s all;
}

.InGreen:hover {
  background: #00a300;
}

.InGreen:hover span.colorGreen {
  color: #fff;
}

input .InGreen {
  color: #00a300;
  transition: 0.8s all;
  border: 1px solid #00a300;
  width: 76px;
  font-size: 14px;
}

.main-div {
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.main-div input {
  border: 1px solid #dfe1e6;
  border-radius: 10px 10px 10px 10px;
  outline: none;
}

.start-table p {
  font-weight: 700;
  font-size: 26px;
  color: #000;
  line-height: 32px;
  margin-bottom: 5px;
}

.custom-tooltip {
  cursor: pointer;
}

.td-status-msg {
  width: 550px;
}

.main-details-card {
  display: flex;
  gap: 40px;
  text-align: left;

  flex-wrap: wrap;
  margin-top: 11px;
}

.main-details-card .single-card-box {
  text-align: center;
}

.single-card-box h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.middle-detail-card {
  margin: 0 0 20px 0;
}

.card-header .nav.nav-pills.card-header-pills {
  justify-content: space-between;
  align-items: center;
  margin: 2px 6px;
}

.action-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: baseline;
}

@media screen and (max-width: 1450px) {
  .main-details-card {
    display: flex;
    justify-content: space-evenly;
    gap: 21px;
  }

  .card-header .nav.nav-pills.card-header-pills {
    justify-content: space-around;
    align-items: center;
    margin: 2px 0px;
  }
}

@media screen and (max-width: 1450px) {
  .td-status-msg {
    width: 450px;
  }
}

.single-card-box .status-td.completed {
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  background: rgba(63, 138, 255, 0.2);
  border: 0;
  padding: 4px 8px;
  color: #3f8aff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .action_button {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.databtn {
  border: none;
  padding: 4px;
  border-radius: 4px;
  background-color: #20b89e;
  color: #fff;
}

.skeleton-loder {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  will-change: transform;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: loading 2.6s linear infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.skeleton-table-h {
  height: 40px;
}

.skeleton-loder:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0));
  animation: shimmer 5s infinite;
  content: "";
  position: absolute;
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0));
}

.wrapper .main-content {
  background-color: #f3f7fb;
}

.middle-detail-card .card {
  background-color: transparent;
  border: 0;
}

.top {
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  padding: 20px;
  margin-bottom: 40px;
}

.main-div {
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.main-details-card {
  display: flex;
  gap: 40px;
  text-align: left;
  margin-top: 0;
  flex-wrap: nowrap;
}

.leftcards {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  gap: 24px;
  display: flex;
  flex-direction: column;
  /* padding-left: 64px; */
  position: relative;
}

/* .leftcards::before {
      content: "";
      border: 1px solid #828282;
      position: absolute;
      left: 40px;
      width: 0px;
      height: 90%;
    } */

/* .main-details-card .single-card-box::before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 31px;
      background: #3f8aff;
      border-radius: 100%;
    } */

.card-body {
  flex: auto;
  min-height: 1px;
  padding: 0;
}

.main-details-card .single-card-box {
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-radius: 4.036px;
  border: 0.807px solid #d2d2d2;
  background: #f6f6f6;
  align-items: center;
  height: 40px;
  padding: 0 20px;
}

.single-card-box h4 {
  color: #0f3274;
  font-size: 16.951px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.single-card-box p {
  color: rgba(15, 50, 116, 0.7);
  font-size: 15.336px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.rightchart {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rightchart .chartheading {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 30px;
  top: 30px;
}

.top .action-btn .secondary-btn-outline {
  padding: 5px 20px;
  background-color: #3f8aff;
  border: 0;
  color: #fff;
}

.main-div>.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 0;
  padding: 30px;
  border-radius: 24.808px;
  background: var(--White, #fff);
  box-shadow: 0px 4.962px 12.404px 0px rgba(63, 209, 255, 0.3);
}

.main-div>.card .tabs .card-header {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.main-div>.card .tabs .card-header .nav.nav-pills {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}

.main-div>.card .tabs .card-header .nav.nav-pills li.nav-item {
  width: 100%;
}

.main-div>.card .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
  border-radius: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 18px 20px;
  background-color: #f7f7f7;
  color: #000;
  font-size: 19.847px;
  font-style: normal;
  font-weight: 600;
}

.main-div>.card .tabs .card-header .nav.nav-pills li.nav-item .nav-link.active {
  background-color: #3f8aff;
  color: #ffffff;
}

.main-details-card .single-card-box .R1 {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(90, 175, 75, 0.2);
  border-radius: 40px;
}

.first-tables.table-new thead tr {
  background-color: transparent;
}

.table-new tr:nth-child(odd) {
  background-color: #f7f6fe;
}

.table-new tr td.duplicate span {
  border-radius: 26.049px;
  background: rgba(210, 76, 52, 0.2);
  padding: 4px 15px;
  border: 0;
  color: #d24c34;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
}

.table-new tr td.completed span {
  border-radius: 26.049px;
  background: rgba(32, 184, 158, 0.2);
  padding: 4px 15px;
  border: 0;
  color: #5aaf4b;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
}

.table-new tr td .databtn {
  border-radius: 4.406px;
  background: #3f8aff;
  color: var(--White, #fff);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
}

.action_button.InGreen {
  background: transparent;
}

button.action_button.InGreen .colorGreen {
  color: #fff;
}

.main-div>.card .search-container {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.main-div>.card .search-container .searchbar {
  width: 29.5%;
  margin-left: 0;
  padding: 15px 20px;
  padding-left: 50px;
}

.main-div>.card .search-container .searchicon {
  position: absolute;
  left: 15px;
  top: 15px;
}

.table-new tr td.in_progress span {
  border-radius: 26.049px;
  background: rgb(79 139 255 / 30%);
  padding: 4px 15px;
  border: 0;
  color: #4f8bff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
}

.leftcards.loaderbox::before {
  border-color: #f0f0f0;
}

.rightchart.skeletonbox {
  height: 100%;
  padding: 0;
  box-shadow: none;
  justify-content: space-between;
}

.rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
  height: 350px;
  border-radius: 100%;
  width: 350px;
  position: relative;
  top: -60px;
  left: -50px;
}

.single-card-box .status-td.completed {
  background: rgba(32, 184, 158, 0.2);
  color: #5aaf4b;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.in_progress {
  background: rgba(247, 143, 0, 0.2);
  color: #f9c000;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate {
  background: rgba(210, 76, 52, 0.2);
  color: #d24c34;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate.paused {
  background: #b8b8b8;
  color: #000;
}

.innerleftcards .single-card-box {
  margin-bottom: 10px;
}

.main-details-card .single-card-box.body-task-text {
  height: auto;
  padding: 6px 6px;
}

.main-details-card .single-card-box.body-task-text h4 {
  width: 350px;
}

.innerleftcards {
  background: #f6f6f6;
  border: 0.807px solid #d2d2d2;
  border-radius: 4.036px;
}

.innerleftcards .single-card-box {
  background: transparent;
  border: unset;
  padding: 0;
}

.upper-setting-box h6 {
  margin: 0 7px 0 0;
  font-weight: bold;
}

.upper-setting-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 0 10px #eee;
  border-radius: 15px;
  padding: 10px;
}

.inner-single-setting {
  min-width: 155px;
  margin-top: 10px;
}

.inner-single-setting .status-td {
  font-size: 12px;
  padding: 0px 9px;
}

.upper-setting-box p {
  margin: 0;
}

.middle-setting-box .single-card-box {
  background: transparent;
  border: unset;
  width: 50%;
  padding: 0 10px 0 0;
  border-bottom: 1px solid #eee;
}

.middle-setting-box {
  display: flex;
  flex-wrap: wrap;
}

.middle-setting-box .single-card-box h4 {
  text-align: left;
  font-size: 14px;
}

.main-details-card .bottom-settings-box .single-card-box {
  padding: 0;
  background: transparent;
  border: unset;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
}

.main-details-card .bottom-settings-box .single-card-box:last-child {
  margin-bottom: 0px;
  border-bottom: unset;
}

.main-details-card .bottom-settings-box .single-card-box.body-task-text {
  align-items: self-start;
}

.body-task-text h4 {
  text-align: left;
}

.body-task-text p {
  text-align: right;
}

.main-details-card .error-message-main .single-card-box {
  background: transparent;
  padding: 5px;
  border: 1px solid #eee;
}

.inner-single-setting .status-td.duplicate {
  background: #edd4ce;
  border-radius: 15px;
  font-weight: bold;
}

/* Seach filter container */
.action-btn.filter-container {
  justify-content: space-between;
}

.action-btn.filter-container div {
  width: 100%;
}

.action-btn.filter-container div input {
  margin: 0;
  padding: 10px 20px;
  height: 45px;
}

.action-btn.filter-container .refresh-btn {
  max-width: 125px;
}

.top {
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  padding: 20px;
  margin-bottom: 15px;
}

.action-btn.filter-container {
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  padding: 20px;
  margin-bottom: 15px;
}

button.secondary-btn-outline {
  width: 100%;
  height: 45px;
}

.action-btn.filter-container div .form-control {
  height: 45px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-color: #dfe1e6;
  border-radius: 10px;
}

.action-btn.filter-container .single-card-box .custom-select {
  height: 45px;
  border-color: #dfe1e6;
  border-radius: 10px;
}

.search-container {
  position: relative;
}

.action-btn.filter-container .searchicon {
  position: absolute;
  left: 10px;
  top: 9px;
  height: 24px;
  width: 24px;
}

.action-btn.filter-container div input {
  padding-left: 40px;
  width: 100%;
}

.action-btn.filter-container .refresh-btn .secondary-btn-disabled {
  width: 100%;
  padding: 9px;
  border-radius: 5px;
}

/* .action-btn.filter-container .refresh-btn button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  } */
p.no-record-found {
  text-align: center;
  font-size: 16px;
}

.action-btn.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.action-btn.filter-container>div {
  width: 100%;
  max-width: 333px;
  min-width: 333px;
}

.action-btn.filter-container .refresh-btn {
  max-width: 125px;
  min-width: 125px;
}

/* 29/04/2024 */

/* webhook css */
.dg-new-action-filter.action-btn.filter-container {
  align-items: center;
}

.dg-new-action-filter.action-btn.filter-container .form-control.reportrange-text {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 45px;
  border: 1px solid #dfe1e6;
}

table.dp-webhook-table.first-tables.table-new tr td .databtn {
  border-radius: 4.406px;
  background: unset;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.dg-imail-patch {
  width: 242px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: block;
  position: relative;
  overflow: hidden;
  /* overflow: visible; */
}

.overflow-vissible {
  position: relative;
}

.dg-imail-patch+.dg-tooltip {
  display: none;
}

.dg-tooltip:before {
  background: #000;
  position: absolute;
  z-index: 99;
  width: 15px;
  height: 15px;
  content: "";
  bottom: -11px;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
}

.dg-imail-patch:hover+.dg-tooltip {
  display: block;
  top: -47px;
  position: absolute;
  left: 0;
  background: #000;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  width: 242px;
  word-wrap: break-word;
  white-space: normal;
}

table.dp-webhook-table.first-tables.table-new tr td .databtn img {
  width: 16px;
  height: 16px;
}

table.dp-webhook-table.first-tables.table-new tr td {
  font-weight: 400;
}

.dp-webhook-table.first-tables.table-new tr td.duplicate span {
  font-weight: 400;
  border-radius: 4px;
  background: rgb(210 76 52 / 10%);
  color: #d24c34;
  width: 72px;
  height: 28px;
  border: 1px solid rgba(255, 68, 59, 0.2);
  text-align: center;
}

.cross-button {
  width: 16px;
  height: 16px;
  background: red;
  border-radius: 100%;
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dp-webhook-table.first-tables.table-new tr td.completed span {
  border-radius: 4px;
  font-size: 12.404px;
  font-weight: 400;
  border: 1px solid rgba(44, 169, 104, 0.1882352941);
}

.cross-button svg,
.true-icon svg {
  width: 10px;
  height: 10px;
}

table.dp-webhook-table.first-tables.table-new thead {
  border-bottom: 2px solid rgba(0, 0, 0, 0.050980392156862);
}

table.dp-webhook-table.first-tables.table-new thead .table_head {
  background: none;
  font-variant: JIS04;
  text-align: left;
  padding: 2px 18px;
  font-size: 12px;
  color: #7a869a !important;
  font-weight: 600;
  text-transform: uppercase;
}

.true-icon {
  width: 16px;
  height: 16px;
  background: green;
  border-radius: 100%;
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .top p {
    font-size: 20px;
  }
}

@media screen and (max-width: 580px) {
  .top p {
    font-size: 18px;
  }

  .action-btn.filter-container>div {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .action-btn.filter-container .refresh-btn {
    max-width: 100%;
    min-width: 100%;
  }
}
</style>
